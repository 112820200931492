<script setup lang="ts">
const { breadcrumbs } = useBreadcrumbs();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
</script>
<template>
  <div class="py-5 relative z-10 mt-10 md:mt-0">
    <div class="container">
      <div class="flex flex-wrap gap-x-8 md:gap-x-10 gap-y-[10px]">
        <div class="font-inter">
          <a href="javascript:history.back()" class="flex gap-2 sm:gap-3 items-center"><span class="arrow-ico text-[0px]">
                                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.08 8.95099L10.0533 14.2777L8.53333 15.6094L0 7.99978L8.53333 0.390137L10.0533 1.72183L4.08 7.04858H17.0667V8.95099H4.08Z" fill="#131313"/>
                                </svg>
                            </span> {{$t('global.back')}}</a>
        </div>
        <div class="breadcrumb uppercase hidden md:block">
          <ul class="flex">
            <li class="pe-2 sm:pe-3">
              <NuxtLink
                  :to="formatLink(`/`)"
                  class="underline"
              >
                {{ $t("home") }}
              </NuxtLink>
            </li>
            <li
                v-for="(breadcrumb, index) in breadcrumbs"
                :key="breadcrumb.path"
                class="before:content-['/'] pe-2 sm:pe-3 before:pe-2 before:sm:pe-3"
            >
              <NuxtLink
                  v-if="breadcrumb.path"
                  :to="formatLink(breadcrumb.path)"
              >
                {{ breadcrumb.name }}
              </NuxtLink>
              <span v-else
                    class="inline-flex items-center text-sm font-medium text-secondary-700 dark:text-secondary-400 dark:hover:text-white"
              >
                {{ breadcrumb.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
